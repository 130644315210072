<template>
  <div class="pt-4">
    <!--<v-row class="mb-1 mt-1">
      <v-col v-if="viewVersions" cols="12" sm="3" md="3" lg="3" offset-lg="7" offset-sm="6" class="py-0 mb-3">
        <v-select :items="listadoVersiones" v-model="versionFiltro" @change="listarDetalles" label="Versión"></v-select>
      </v-col>
      <v-col
        cols="12"
        :sm="!viewVersions ? 3 : 2"
        :md="!viewVersions ? 3 : 2"
        :lg="!viewVersions ? 3 : 2"
        :offset-lg="!viewVersions ? '9' : ''"
        class="py-0 mb-3"
      >
        <v-tooltip bottom v-if="!viewVersions">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small class="mt-3 mx-3" @click="guardar" v-bind="attrs" v-on="on">
              Guardar
            </v-btn>
          </template>
          <span>Guardar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" dark small class="mt-3 ml-3" @click="cancel" v-bind="attrs" v-on="on">
              {{ !viewVersions ? 'Cancelar' : 'Regresar' }}
            </v-btn>
          </template>
          <span>Cancelar</span>
        </v-tooltip>
      </v-col>
    </v-row>-->
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-row class="px-2 pt-2 pb-5 flex-column-reverse d-md-inline-flex flex-md-row">
        <v-col cols="12" md="10" sm="12" class="mt-4">
          <v-row>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-select
                dense
                :items="listadoFormatos"
                v-model="formato"
                disabled
                :rules="[rules.required]"
                label="Formato"
              ></v-select>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field
                dense
                label="Usuario"
                :rules="[rules.required]"
                :value="usuario"
                :return-object="false"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field
                dense
                label="Responsable"
                :rules="[rules.required]"
                :value="responsable"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-select
                dense
                :items="listadoAreas"
                v-model="area"
                :rules="[rules.required]"
                @change="cargarSubareas"
                disabled
                label="Área"
              ></v-select>
            </v-col>
            <v-col v-if="idOpexCampo" cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field dense label="Estado" v-model="estado" :rules="[rules.required]" disabled></v-text-field>
            </v-col>
            <!--<v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field
                label="Periodo"
                v-model="periodo"
                :disabled="idOpexCampo ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>-->
            <v-col v-if="idOpexCampo" cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field dense label="Fecha" v-model="fecha" :rules="[rules.required]" disabled></v-text-field>
            </v-col>
            <v-col v-if="idOpexCampo" cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field dense label="Hora" v-model="hora" :rules="[rules.required]" disabled></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field
                dense
                label="Observaciones"
                v-model="observacion"
                :disabled="viewVersions ? true : false"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-text-field
                dense
                label="Versión Actual"
                v-model="version"
                :rules="[rules.required]"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2" sm="6" class="py-0 px-3">
              <v-select
                dense
                :items="listadoEdiciones"
                v-model="tipoedicion"
                :rules="[rules.required]"
                :return-object="false"
                disabled
                label="Edición"
              ></v-select>
            </v-col>
            <!--<v-col cols="6" md="3" sm="6" class="py-0 px-3">
              <div><span style="font-size: 12px;">Múltiples Áreas</span></div>
              <v-radio-group
                v-model="multiplesAreas"
                :rules="[rules.required]"
                :disabled="idOpexCampo ? true : false"
                row
                class="pt-0 mt-0"
              >
                <v-radio label="Sí" value="SI"></v-radio>
                <v-radio label="No" value="NO"></v-radio>
              </v-radio-group>
            </v-col>-->
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-divider class="d-block d-md-none mb-2"></v-divider>
        <v-col cols="12" md="2" sm="12" class="d-flex flex-column justify-end align-center">
          <v-select
            v-if="viewVersions"
            dense
            :items="listadoVersiones"
            v-model="versionFiltro"
            @change="listarDetalles"
            label="Versión"
          ></v-select>
          <v-tooltip bottom v-if="!viewVersions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn block color="primary" dark small @click="guardar" v-bind="attrs" v-on="on">
                Guardar
              </v-btn>
            </template>
            <span>Guardar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn block color="red" class="my-3" dark small @click="cancel" v-bind="attrs" v-on="on">
                {{ !viewVersions ? 'Cancelar' : 'Regresar' }}
              </v-btn>
            </template>
            <span>Cancelar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mb-4"></v-divider>
    <v-row class="my-2 d-flex d-md-none d-lg-none align-center">
      <v-col cols="8" sm="2" md="2" offset-md="8">
        <v-text-field dense hide-details filled label="MONTO TOTAL" disabled v-model="calculoMontoTotal"></v-text-field>
      </v-col>
      <v-col v-if="!viewVersions" cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="agregar" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar OPEX {{ nombreTipoOpex }}</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="!viewVersions" cols="2" v-auth-acl="'gh-ind-mis_ind-export'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="openDialogImportar" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </template>
          <span>Importar Información</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="listadoFiltrado"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
      :height="height"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">DETALLES OPEX {{ nombreTipoOpex.toUpperCase() }}</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 pt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="d-none d-md-flex d-lg-flex mx-4" vertical></v-divider>
          <div class="w-30 d-none d-md-flex d-lg-flex justify-space-between align-center">
            <v-text-field
              dense
              filled
              label="MONTO TOTAL"
              hide-details
              disabled
              v-model="calculoMontoTotal"
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark small class="mx-4" @click="agregar" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar OPEX {{ nombreTipoOpex }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  small
                  @click="openDialogImportar"
                  v-bind="attrs"
                  v-on="on"
                  v-auth-acl="'gh-ind-mis_ind-export'"
                >
                  <v-icon dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </template>
              <span>Importar Información</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <v-row>
          <v-col cols="3" class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-2" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                  <v-icon small dark>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-2" v-bind="attrs" v-on="on" @click="editDetail(item)">
                  <v-icon small dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-2" v-bind="attrs" v-on="on" @click="duplicateItem(item)">
                  <v-icon small dark>
                    mdi-checkbox-multiple-blank-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Duplicar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.periodo`]="{ item }">
        <v-autocomplete
          v-if="!viewVersions"
          dense
          hide-details
          :items="listadoPeriodos"
          v-model="item.periodo"
          :rules="[rules.required]"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
        <span v-else>{{ item.periodo }}</span>
      </template>
      <template v-slot:[`item.idmoneda`]="{ item }">
        <!--<v-select v-if="!viewVersions" :items="listadoMonedas" v-model="item.idmoneda"></v-select>-->
        <span>{{ item.idmoneda }}</span>
      </template>
      <template v-slot:[`item.precio`]="{ item }">
        <v-text-field v-if="!viewVersions" dense hide-details v-model="item.precio"></v-text-field>
        <span v-else>{{ item.precio }}</span>
      </template>
      <template v-slot:[`item.cantidad`]="{ item }">
        <v-text-field v-if="!viewVersions" dense hide-details v-model="item.cantidad"></v-text-field>
        <span v-else>{{ item.cantidad }}</span>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        <span>{{ item.precio * item.cantidad }}</span>
      </template>
      <template v-slot:[`item.observaciones`]="{ item }">
        <v-text-field v-if="!viewVersions" dense hide-details v-model="item.observaciones"></v-text-field>
        <span v-else>{{ item.observaciones }}</span>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-icon :color="item.activo == 1 ? 'green' : 'red darken-2'" small>
          {{ item.activo == 1 ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <Importar
      :headers="headers"
      :dialog="dialogImportar"
      :idTipoOpex="idTipoOpex"
      :tipoOpex="tipoOpex"
      :nombreTipoOpex="nombreTipoOpex"
      @close="closeImportar"
      @importar="importar"
    ></Importar>
    <OpexDetalleForm
      v-auth-acl="'gh-ind-mis_ind-view'"
      :dialog="dialogDetalleForm"
      :listadoLocacionesParam="listadoLocaciones"
      :listadoSubAreasParam="listadoSubAreas"
      :listadoServiciosParam="listadoServicios"
      :listadoGruposServiciosParam="listadoGruposServicios"
      :listadoTiposEquipoParam="listadoTiposEquipo"
      :listadoPeriodos="listadoPeriodos"
      :itemParam="itemSelected"
      :idTipoOpex="idTipoOpex"
      :tipoOpex="tipoOpex"
      :nombreTipoOpex="nombreTipoOpex"
      @guardar="guardarItemDetalle"
      @close="closeDetalleForm"
    ></OpexDetalleForm>
  </div>
</template>

<script>
import FormOpexService from '../services/FormOpexService';
import Swal from 'sweetalert2';
import { decryptAES } from '@/components4x/utils/utils4x';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'OpexFormulario',
  components: {
    OpexDetalleForm: () => import('../components/OpexDetalleForm'),
    Importar: () => import('./Importar')
  },
  data: () => ({
    height: 130,
    showLoading: false,
    formOpexService: null,
    idOpexCampo: null,
    search: '',
    listadoAreas: [],
    itemSelected: null,
    dialogDetalleForm: false,
    versionFiltro: '',
    listadoVersiones: [{ value: '', text: 'TODAS' }],
    listadoFormatos: [],
    listadoEdiciones: [
      { value: 'M', text: 'Manual' },
      { value: 'I', text: 'Importada' }
    ],
    listadoMonedas: [
      { value: 'D', text: 'Dólares' },
      { value: 'S', text: 'Soles' },
      { value: 'E', text: 'Euros' }
    ],
    listadoLocaciones: [],
    listadoSubAreas: [],
    listadoServicios: [],
    listadoGruposServicios: [],
    listadoTiposEquipo: [],
    validForm: true,
    formato: '',
    area: '',
    observacion: '',
    version: '',
    tipoedicion: 'M',
    multiplesAreas: 'NO',
    estado: '',
    periodo: '',
    fecha: '',
    hora: '',
    usuario: '',
    responsable: '',
    headersGeneral: [
      { text: 'N°', value: 'numero', width: '100', class: 'header-items-fixed-ind nth-child1 primary', fixed: true },
      {
        text: 'OPCIONES',
        value: 'opciones',
        width: '120',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'LOCACIÓN BUDGET', value: 'idlocacionbudget', width: '180', class: 'primary' },
      { text: 'DESCRIPCIÓN DETALLADA', value: 'descripciondetallada', width: '230', class: 'primary' },
      { text: 'SERVICIO', value: 'idservicio', width: '120', class: 'primary' },
      { text: 'CUENTA MAYOR', value: 'idcuentamayor', width: '160', class: 'primary' },
      { text: 'CUENTA CONTABLE', value: 'idcuentacontable', width: '200', class: 'primary' },
      { text: 'SUB AREA', value: 'idsubarea', width: '120', class: 'primary' },
      { text: 'CECO', value: 'idceco', width: '120', class: 'primary' },
      { text: 'TIPO DE GASTO', value: 'idtipogastobudget', width: '160', class: 'primary' },
      { text: 'PERIODO', value: 'periodo', width: '120', class: 'primary' },
      { text: 'MONEDA', value: 'idmoneda', width: '120', class: 'primary' },
      { text: 'PRECIO', value: 'precio', width: '120', class: 'primary' },
      { text: 'CANTIDAD', value: 'cantidad', width: '120', class: 'primary' },
      { text: 'TOTAL', value: 'monto', width: '120', class: 'primary' },
      { text: 'OBSERVACIONES', value: 'observaciones', width: '180', class: 'primary' },
      { text: 'PROYECTO', value: 'proyecto', width: '180', class: 'primary' },
      { text: 'PRIORIDAD', value: 'prioridad', width: '130', class: 'primary' }
    ],
    headersCampo: [
      { text: 'N°', value: 'numero', width: '100', class: 'header-items-fixed-ind nth-child1 primary', fixed: true },
      {
        text: 'OPCIONES',
        value: 'opciones',
        width: '120',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'LOCACIÓN BUDGET', value: 'idlocacionbudget', width: '180', class: 'primary' },
      { text: 'DESCRIPCIÓN DETALLADA', value: 'descripciondetallada', width: '300', class: 'primary' },
      { text: 'GRUPO SERVICIO', value: 'gruposervicio', width: '170', class: 'primary' },
      { text: 'SERVICIO', value: 'idservicio', width: '120', class: 'primary' },
      { text: 'CUENTA MAYOR', value: 'idcuentamayor', width: '160', class: 'primary' },
      { text: 'CUENTA CONTABLE', value: 'idcuentacontable', width: '200', class: 'primary' },
      { text: 'SUB AREA', value: 'idsubarea', width: '120', class: 'primary' },
      { text: 'CECO', value: 'idceco', width: '120', class: 'primary' },
      { text: 'TIPO DE GASTO', value: 'idtipogastobudget', width: '160', class: 'primary' },
      { text: 'PERIODO', value: 'periodo', width: '120', class: 'primary' },
      { text: 'MONEDA', value: 'idmoneda', width: '120', class: 'primary' },
      { text: 'PRECIO', value: 'precio', width: '120', class: 'primary' },
      { text: 'CANTIDAD', value: 'cantidad', width: '120', class: 'primary' },
      { text: 'TOTAL', value: 'monto', width: '120', class: 'primary' },
      { text: 'OBSERVACIONES', value: 'observaciones', width: '180', class: 'primary' },
      { text: 'PROYECTO', value: 'proyecto', width: '180', class: 'primary' },
      { text: 'PRIORIDAD', value: 'prioridad', width: '130', class: 'primary' }
    ],
    headersTI: [
      { text: 'N°', value: 'numero', width: '100', class: 'header-items-fixed-ind nth-child1 primary', fixed: true },
      {
        text: 'OPCIONES',
        value: 'opciones',
        width: '120',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'LOCACIÓN BUDGET', value: 'idlocacionbudget', width: '180', class: 'primary' },
      { text: 'SUB AREA', value: 'idsubarea', width: '120', class: 'primary' },
      { text: 'CECO', value: 'idceco', width: '120', class: 'primary' },
      { text: 'TIPO DE GASTO', value: 'idtipogastobudget', width: '160', class: 'primary' },
      { text: 'TIPO DE EQUIPO', value: 'idtipoequipo', width: '120', class: 'primary' },
      { text: 'EQUIPO', value: 'equipo', width: '120', class: 'primary' },
      { text: 'GAMA', value: 'gama', width: '120', class: 'primary' },
      { text: 'CONTRATO', value: 'contrato', width: '130', class: 'primary' },
      { text: 'PERIODO', value: 'periodo', width: '120', class: 'primary' },
      { text: 'MONEDA', value: 'idmoneda', width: '120', class: 'primary' },
      { text: 'PRECIO', value: 'precio', width: '120', class: 'primary' },
      { text: 'CANTIDAD', value: 'cantidad', width: '120', class: 'primary' },
      { text: 'TOTAL', value: 'monto', width: '120', class: 'primary' },
      { text: 'OBSERVACIONES', value: 'observaciones', width: '180', class: 'primary' },
      { text: 'PROYECTO', value: 'proyecto', width: '180', class: 'primary' },
      { text: 'PRIORIDAD', value: 'prioridad', width: '130', class: 'primary' }
    ],
    headers: [],
    listado: [],
    rules: {
      required: (value) => !!value || 'Campo requerido.'
      /*min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match"*/
    },
    listadoPeriodos: [],
    dialogImportar: false
  }),
  props: {
    idTipoOpex: {
      type: Number,
      default: null
    },
    tipoOpex: {
      type: String,
      default: ''
    },
    nombreTipoOpex: {
      type: String,
      default: ''
    },
    viewVersions: {
      type: Number,
      default: null
    },
    newVersion: {
      type: Number,
      default: null
    }
  },
  computed: {
    listadoFiltrado() {
      let listado = this.listado;
      if (!this.viewVersions) {
        listado = listado.filter((element) => element.activo == 1);
        listado = listado.map((element, index) => {
          element.numero = index + 1;
          return element;
        });
      }
      return listado;
    },
    calculoMontoTotal() {
      let total = 0;
      this.listadoFiltrado.forEach((el) => {
        total += el.cantidad * el.precio;
      });
      return total.toFixed(2);
    }
  },
  watch: {},
  methods: {
    inicializarItem() {
      this.area = '';
      this.observacion = '';
      this.version = '';
      this.tipoedicion = '';
      this.multiplesAreas = null;
      this.estado = '';
      this.periodo = '';
      this.fecha = '';
      this.hora = '';
      this.listado = [];
    },
    async editDetail(item) {
      this.itemSelected = item;
      this.dialogDetalleForm = true;
    },
    async cargarAreas() {
      this.listadoAreas = [];
      const res = await this.formOpexService.get().execResource('areas', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      this.listadoAreas = res.map((element) => {
        return { value: element.idarea, text: element.area };
      });
      if (this.listadoAreas.length > 0) this.area = this.listadoAreas[0].value;
      await this.cargarSubareas();
    },
    async headersType() {
      if (this.tipoOpex == 'ti') {
        this.headers = this.headersTI;
      } else if (this.tipoOpex == 'camp') {
        this.headers = this.headersCampo;
      } else {
        this.headers = this.headersGeneral;
      }
    },
    async initialize() {
      //await Promise.all([
      await this.cargarAreas();
      await this.cargarLocaciones();
      if (this.idTipoOpex != 1) await this.cargarServicios();
      else await this.cargarGruposServicios();
      await this.cargarFormatos();
      await this.cargarPeriodos();
      await this.cargarTiposEquipo();
      //]);
      if (this.viewVersions) {
        this.headers[0].class = 'primary';
        this.headers[0].fixed = false;
        this.headers.splice(1, 1);
        this.headers.splice(1, 0, { text: 'VERSIÓN', value: 'version', width: '110', class: 'primary' });
        await this.cargarVersiones();
      }
      if (this.idOpexCampo !== undefined) {
        await this.listarDetalles();
      } else {
        this.usuario = decryptAES(localStorage.getItem('user'));
        this.responsable = this.usuario;
        this.version = '1.00';
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    closeDetalleForm() {
      this.itemSelected = null;
      this.dialogDetalleForm = false;
    },
    async deleteItem(item) {
      let index = this.listado.findIndex((el) => el.uuid == item.uuid);
      if (item.accion == 1) {
        this.listado[index].activo = 0;
      } else {
        this.listado.splice(index, 1);
      }
    },
    async duplicateItem(item) {
      let index = this.listado.findIndex((el) => el.uuid == item.uuid);
      this.listado.splice(index + 1, 0, { ...item, item: '', accion: 0, uuid: uuidv4() });
      this.listado = this.listado.map((element, index) => {
        element.numero = index + 1;
        return element;
      });
    },
    guardarItemDetalle(item) {
      if (this.itemSelected != null) {
        let index = this.listado.findIndex((el) => el.uuid == item.uuid);
        this.listado.splice(index, 1, { ...item });

        if (this.multiplesAreas == 'NO') {
          this.listado = this.listado.map((element) => {
            element.idsubarea = item.idsubarea;
            return element;
          });
        }
      } else {
        if (item.periodo.length > 0) {
          let periodos = item.periodo;
          periodos.forEach((el) => {
            this.listado.push({ ...item, numero: this.listado.length, uuid: uuidv4(), periodo: el });
          });
        } else {
          item.numero = this.listado.length;
          item.uuid = uuidv4();
          this.listado.push(item);
        }
      }
      this.height = 300;
      this.closeDetalleForm();
    },
    openDialogImportar() {
      if (this.area != '') {
        this.dialogImportar = true;
      } else {
        this.alertDialog('warning', 'Debe seleccionar un área');
      }
    },
    closeImportar() {
      this.dialogImportar = false;
    },
    importar(listado) {
      listado.forEach((el) => {
        this.listado.push({ ...el, numero: this.listado.length, uuid: uuidv4(), periodo: el.periodo.toString() });
      });
      this.height = 300;
    },
    agregar() {
      if (this.area != '') {
        this.itemSelected = null;
        this.dialogDetalleForm = true;
      } else {
        this.alertDialog('warning', 'Debe seleccionar un área');
      }
    },
    async listarDetalles() {
      this.showLoading = true;
      this.listado = [];
      let object = {};
      let api = '';
      if (!this.viewVersions) {
        api = this.tipoOpex == 'ti' ? 'detallesOpexTI' : 'detallesOpexCampo';
        if (this.tipoOpex == 'ti') {
          object = { idopextibudget: this.idOpexCampo };
        } else {
          object = { idopexbudget: this.idOpexCampo };
        }
        object = {
          idopexbudget: this.idOpexCampo,
          idopextibudget: this.idOpexCampo
        };
      } else {
        api = this.tipoOpex == 'ti' ? 'detallesPorVersionOpexTI' : 'detallesPorVersionOpexCampo';
        object = {
          idopexbudget: this.idOpexCampo,
          idopextibudget: this.idOpexCampo,
          listarversion: this.versionFiltro
        };
      }
      const res = await this.formOpexService.get().execResource(api, object);
      if (res.length > 0) {
        this.formato = res[0].Formato;
        this.usuario = res[0].Usuario;
        this.area = await res[0].Area;
        await this.cargarSubareas();
        this.observacion = res[0].Observaciones;
        this.version = res[0].Version;
        if (this.newVersion == 1) {
          this.version++;
        }
        this.version = Number(this.version).toFixed(2);
        this.tipoedicion = res[0].Edicion;
        this.multiplesAreas = res[0].MultiplesAreas;
        this.estado = res[0].Estado;
        this.periodo = res[0].Periodo;
        this.fecha = res[0].Fecha;
        this.hora = res[0].Hora;
        this.responsable = res[0].Responsable;
        if (res[0].Detalle != null && res[0].Detalle.length > 0) {
          this.listado = res[0].Detalle.map((element, index) => {
            let clase = element.activo == 0 ? 'item-inactive' : '';
            clase = this.viewVersions == undefined ? 'class-items-fixed-ind' + clase : clase;
            let el = {
              uuid: uuidv4(),
              numero: index + 1,
              opciones: ' ',
              ...element,
              class: clase,
              accion: 1
            };
            return el;
          });
        }
      }

      this.height = 300;
      this.showLoading = false;
    },
    async cargarLocaciones() {
      this.listadoLocaciones = [];
      const res = await this.formOpexService.get().execResource('locaciones', {
        tipo: this.idTipoOpex == 1 ? 2 : 0
      });
      this.listadoLocaciones = res.map((element) => {
        return { value: element.idlocacionbudget, text: element.Descripcion };
      });
    },
    async cargarSubareas() {
      this.listadoSubAreas = [];
      const res = await this.formOpexService.get().execResource('subAreas', {
        idarea: this.area
      });
      this.listadoSubAreas = res.map((element) => {
        return { value: element.idsubarea, text: element.Descripcion };
      });
    },
    async cargarServicios() {
      this.listadoServicios = [];
      const res = await this.formOpexService.get().execResource('servicios', { grupo: '' });
      this.listadoServicios = res.map((element) => {
        return {
          value: element.idservicio + '-' + element.idcuentacontable + '-' + element.idcuentamayor,
          text: element.Servicio,
          ...element
        };
      });
    },
    async cargarGruposServicios() {
      this.listadoGruposServicios = [];
      const res = await this.formOpexService.get().execResource('gruposServicios');
      this.listadoGruposServicios = res.map((element) => {
        return {
          value: element.GrupoServicio,
          text: element.GrupoServicio,
          ...element
        };
      });
    },
    async cargarTiposEquipo() {
      this.listadoTiposEquipo = [];
      const res = await this.formOpexService.get().execResource('tiposEquipo');
      this.listadoTiposEquipo = res.map((element) => {
        return {
          value: element.idtipoequipo,
          text: element.descripcion,
          ...element
        };
      });
    },
    async cargarPeriodos() {
      this.listadoPeriodos = [];
      const res = await this.formOpexService.get().execResource('periodos');
      this.listadoPeriodos = await res.Periodos.map((element) => {
        return { value: element.periodo.toString(), text: element.periodo.toString() };
      });
    },
    cancel() {
      this.inicializarItem();
      let route = ('/presupuesto/opex-' + this.nombreTipoOpex.toLowerCase()).replace(' ', '-');
      this.$router.push({ path: route });
    },
    async guardar() {
      if (this.$refs.form.validate()) {
        if (this.listado.length > 0) {
          const confirmed = await Swal.fire({
            icon: 'warning',
            title: '¿Está seguro de guardar los cambios?',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
          });
          if (confirmed.isConfirmed) {
            this.showLoading = true;
            this.listado = this.listado.map((element) => {
              element.monto = element.cantidad * element.precio;
              return element;
            });
            let objeto = {
              idempresa: decryptAES(localStorage.getItem('emp')),
              periodo: this.periodo,
              idleyendaformato: Number(this.formato),
              idarea: this.area,
              observaciones: this.observacion,
              multipleareas: this.multiplesAreas == 'SI' ? 1 : 0,
              cambiar_version: this.newVersion == 1 ? '1' : '0',
              tipoedicion: this.tipoedicion
            };
            let api = '';
            if (this.tipoOpex == 'ti') {
              objeto['idopextibudget'] = this.idOpexCampo;
              objeto['detalle_opextibudget'] = this.listado;
              objeto['subarea'] = this.listado[0].idsubarea;
              api = this.idOpexCampo === undefined ? 'guardarCabeceraDetallesTI' : 'actualizarDetallesTI';
            } else {
              objeto['idopexbudget'] = this.idOpexCampo;
              objeto['detalle_opexbudget'] = this.listado;
              objeto['subarea'] = this.listado[0].idsubarea;
              api = this.idOpexCampo === undefined ? 'guardarCabeceraDetalles' : 'actualizarDetalles';
            }

            const res = await this.formOpexService.post().execResource(api, objeto);
            this.showLoading = false;
            this.alertDialog(res.status ? 'success' : 'error', res.data);
            if (res.status) this.cancel();
          }
        } else {
          this.alertDialog('error', 'Debe ingresar al menos 1 detalle');
        }
      }
    },
    async cargarVersiones() {
      let url = this.tipoOpex == 'ti' ? 'versionesOpexTi' : 'versionesOpexCampo';
      const res = await this.formOpexService.get().execResource(url, {
        idopexbudget: this.idOpexCampo,
        idopextibudget: this.idOpexCampo
      });
      res.forEach((element) => {
        this.listadoVersiones.push({ value: element.Version, text: element.Version });
      });
    },
    async cargarFormatos() {
      const res = await this.formOpexService.get().execResource('listarFormato');
      this.listadoFormatos = res.map((el) => {
        return { value: el.idleyendaformato, text: el.descripcion };
      });
    }
  },
  async created() {
    this.showLoading = true;
    this.formOpexService = this.$httpService(new FormOpexService(), this);
    this.idOpexCampo = this.$route.query.id;
    await this.headersType();
    await this.initialize();
    this.formato = this.idTipoOpex.toString();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.item-inactive {
  background-color: #ff9e9e !important;
}
</style>
