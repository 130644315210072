class FormOpexService {
  constructor() {}
  resources = () => ({
    detallesOpexCampo: {
      uri: `/v1/presupuesto/opex/listar-detalle-opex-campo`,
      method: ['get']
    },
    detallesOpexTI: {
      uri: `/v1/presupuesto/opex/listar-detalle-opex-ti`,
      method: ['get']
    },
    listarFormato: {
      uri: `/v1/presupuesto/listar-formato`,
      method: ['get']
    },
    versionesOpexCampo: {
      uri: `/v1/presupuesto/opex/listar-versiones-opex-campo`,
      method: ['get']
    },
    versionesOpexTi: {
      uri: `/v1/presupuesto/opex/listar-versiones-opex-ti`,
      method: ['get']
    },
    detallesPorVersionOpexCampo: {
      uri: `/v1/presupuesto/opex/listar-detalle-versiones-opex-campo`,
      method: ['get']
    },
    detallesPorVersionOpexTI: {
      uri: `/v1/presupuesto/opex/listar-detalle-versiones-opex-ti`,
      method: ['get']
    },
    areas: {
      uri: `/v1/presupuesto/areas`,
      method: ['get']
    },
    locaciones: {
      uri: `/v1/presupuesto/opex/listar-locacion-combo`,
      method: ['get']
    },
    subAreas: {
      uri: `/v1/presupuesto/opex/listar-subarea-combo`,
      method: ['get']
    },
    servicios: {
      uri: `/v1/presupuesto/opex/listar-servicio-combo`,
      method: ['get']
    },
    gruposServicios: {
      uri: `/v1/presupuesto/listar-articulo-servicio-grupo`,
      method: ['get']
    },
    cecos: {
      uri: `/v1/presupuesto/opex/listar-ceco-combo`,
      method: ['get']
    },
    tiposEquipo: {
      uri: `/v1/presupuesto/opex/tipo-equipo`,
      method: ['get']
    },
    equipos: {
      uri: `/v1/presupuesto/opex/equipo`,
      method: ['get']
    },
    gamasPorEquipo: {
      uri: `/v1/presupuesto/opex/listar-gamas-equipo`,
      method: ['get']
    },
    cuentasMayores: {
      uri: `/v1/presupuesto/opex/listar-cuenta-articulo-servicio`,
      method: ['get']
    },
    periodos: {
      uri: `/v1/presupuesto/listar-periodo-inicio-fin`,
      method: ['get']
    },
    guardarCabeceraDetalles: {
      uri: `/v1/presupuesto/opex/guardar-headerdetalle-opex-campo`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    actualizarDetalles: {
      uri: `/v1/presupuesto/opex/guardar-detalle-opex-campo`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    guardarCabeceraDetallesTI: {
      uri: `/v1/presupuesto/opex/guardar-headerdetalle-opex-ti`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    actualizarDetallesTI: {
      uri: `/v1/presupuesto/opex/guardar-detalle-opex-ti`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    exportarModeloOpex: {
      uri: `/v1/descargar-formato-importar`,
      method: ['get']
    }
  });
}

export default FormOpexService;
